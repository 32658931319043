"use client";

import { useEffect } from "react";

export default function CtaTracker() {
  useEffect(() => {
    const trackClick = (event) => {
      const target = event.target.closest("[data-track]"); // Find elements with tracking attributes
      if (target) {
        const eventName = target.dataset.track || "click_event";

        // Send the event to Google Tag Manager
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "custom_click",
          event_category: "User Interaction",
          event_action: eventName,
        });

        // Debugging purposes.
        // console.log(`Tracked Click: ${eventName}`);
      }
    };

    document.addEventListener("click", trackClick);
    return () => document.removeEventListener("click", trackClick);
  }, []);

  return null; // This component does not render UI
}
